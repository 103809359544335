import { Component, OnInit, Input, input, ChangeDetectionStrategy, signal } from '@angular/core';

import { ITestButtonParams } from './test-button-params';
import { TestIconComponent } from 'src/app/standalone-components/test-icon/test-icon.component';

/**
 * The component is used to display Test Button list item
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-button',
  standalone: true,
  imports: [
    TestIconComponent
  ],
  templateUrl: './test-button.component.html',
  styleUrls: ['./test-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestButtonComponent {

  /**
   * Component parameter that 
   */
  config = signal<ITestButtonParams>(null);
  enabled = signal(false);
}
