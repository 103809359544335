<div class="start-page-container">

     <header></header>

     <test-buttons (buttonClick)="onButtonClick($event)"></test-buttons>

     <div class="install_bar" *ngIf="showInstallButton">
          <round-button [backgroundColor]="'#0088CE'" [labelId]="'start_page.install'"
               (click)="onInstall($event)"></round-button>
     </div>

     <pro-button></pro-button>

     <toolbar>
          <img [src]="eUniversityIcon.url" (click)="onShowAppVersion($event)" class="app-version-button">

          <round-button *ngIf="showInstallButton" class="tb-inst-btn" [backgroundColor]="'#0088CE'"
               [labelId]="'start_page.install'" (click)="onInstall($event)"></round-button>


          <toolbar-language-select>
          </toolbar-language-select>

          <img *ngIf="profileButton != null && profileButton != 'hidden'" [src]="profileButtonIcon"
               (click)="onSelectProfile($event)" class="toolbar-button">

          <toolbar-help-button>
          </toolbar-help-button>
     </toolbar>
</div>