import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';

import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service'
import { TestConfig } from '../../../models/config/config-types'
import { RouterUrlList } from 'src/app/models/url-list';
import { FileCacheService } from 'src/app/services/file-cache.service';
import { Feature } from 'src/app/services/persistent-state/keys';
import { ButtonIcon, IButtonIcon } from 'src/app/modules/common-components/toolbar/toolbar-toggle-button/toolbar-button-icon';
import { AlertDialogService } from 'src/app/modules/common-components/alert-dialog/alert-dialog';
import { ToggleButtonStateIconsService } from 'src/app/modules/common-components/toolbar/toolbar-toggle-button/toggle-button-state-icons.service';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { BrowserInfo, PWADisplayMode } from '../../../global/utils/browser-info';
import { IInstallPromptConfig } from 'src/app/global/services/app-config/i-app-config';
import { ProjConfigService } from 'src/app/global/services/proj-config/proj-config.service';
import { HelpService } from 'src/app/global/services/help/help.service';
import { SapiSubscriptionStorageService } from 'src/app/global/services/subscription-api/sapi-subscription-storage-service';
import { SubscriptionService } from 'src/app/global/services/subscription-api/subscription.service';

/**
 * The component is used to display the application Home(start) page
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css'],
  providers: [HelpService]
})
export class StartPageComponent implements OnInit {

  readonly eUniversityIcon: IButtonIcon = ButtonIcon.eUniversity;
  readonly profileButtonIcon: string = '';

  private subscriptionStorage = inject(SapiSubscriptionStorageService);

  constructor(
    private alert: AlertDialogService,
    private appConfig: AppConfigService,
    private fileCache: FileCacheService,
    private help: HelpService,
    private persistentState: PersistentStateService,
    private projConfig: ProjConfigService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private stateIcons: ToggleButtonStateIconsService
  ) {
    this.profileButtonIcon = this.stateIcons.getIcons('profile').off;
  }
  profileButton: string;
  showInstallButton: boolean = false;

  private deferredPrompt;

  ngOnInit(): void {

    // Check if the install button should be displayed
    this.checkInstallButton();

    // Check if the profile button should be displayed
    this.checkProfileButton();

    // Show the install prompt if the application is not running on an iOS app
    if (!BrowserInfo.isIOSApp) {
      this.showInstallPrompt();
    }
  }

  onButtonClick(testConfig: TestConfig): void {

    // To avoid passing the test id as a parameter, just save it
    this.persistentState.testId = testConfig.testId;
    this.persistentState.testAuthId = testConfig.authId;

    // Also save the lesson test folder (since lesson is optional check for null)
    this.persistentState.set(Feature.LESSON_TEST_FOLDER,
      testConfig.lesson ? testConfig.lesson.testFolder : '');

    // Precache the test files
    this.fileCache.cacheTestFiles(testConfig.testId);

    if (testConfig.protected &&
      this.subscriptionService.loginRequired) {

      this.persistentState.set(
        Feature.LOGIN_PARAMS,
        testConfig.button
      );
      this.router.navigate([RouterUrlList.LOGIN]);

    } else {
      this.router.navigate([RouterUrlList.TEST_OPTIONS]);
    }
  }

  /**
   * 
   * @param event Install button click handler
   */
  onInstall(event: MouseEvent): void {

    event.stopImmediatePropagation();

    // Show the install prompt.
    this.deferredPrompt.prompt();
    // Log the result
    this.deferredPrompt.userChoice.then(
      result => {
        // Hide the install button.
        this.showInstallButton = false;
      }
    );

  }

  /**
   * Profile button click handler
   */
  onSelectProfile(event: MouseEvent): void {
    event.stopPropagation();
    this.router.navigate([RouterUrlList.LOGOUT]);
  }

  onShowAppVersion(event: MouseEvent): void {

    this.alert.show(
      `${this.appConfig.appTitle} ${this.appConfig.appVersion}` +
      (this.subscriptionStorage.exists ?
        `<br><br>User id: ${this.subscriptionStorage.load().userId}` :
        '')
    );
    event.stopPropagation();
  }

  private checkInstallButton(): void {

    window.addEventListener(
      'beforeinstallprompt',
      (event) => {

        // Stash the event so it can be triggered later.
        this.deferredPrompt = event;
        event.preventDefault();
        //alert('Before install event');
        //this.deferredPrompt.prompt();
        this.showInstallButton = true;
      }
    );

  }

  private checkProfileButton(): void {
    // Show the profile toolbar icon if the user is logged in
    this.profileButton = this.subscriptionStorage.exists ?
      'unselected' :
      'hidden';
  }

  // TODO: Refactor the function code as a separate class or move it to InstallPrompt
  private showInstallPrompt(): void {

    // Show the prompt only in browser, buit not standalone application
    // Also show in any mode after pruchase made from the light verion of the application
    const buyAllCategories: boolean = this.persistentState.get(
      Feature.BUY_ALL_CATEGORIES,
      false
    );

    /*console.warn('this.projConfig.demo:',this.projConfig.demo)
    console.warn('buyAllCategories',buyAllCategories);
    console.warn('InstallPrompt.getPWADisplayMode()',InstallPrompt.getPWADisplayMode());
    console.warn('InstallPrompt.isCrhomeBasedBrowser',InstallPrompt.isCrhomeBasedBrowser());*/
    if (
      // If this is a full version started after payment from a demo version 
      // running anywhere (standalone or browser)
      (!this.projConfig.demo && buyAllCategories) ||

      // If the fulll version of the application runs in a browser, 
      // but not as stadalone PWA
      (!this.projConfig.demo &&
        BrowserInfo.getPWADisplayMode() == PWADisplayMode.BROWSER) ||

      // If this is a demo version running in a Chrome based browser
      // The demo version running in Safari must not show the installation dialog
      // If the demo version is added to the Home screen on iOS, then it
      // is im possible to add the full version to the Home screen after 
      // successful payment
      (this.projConfig.demo &&
        BrowserInfo.getPWADisplayMode() == PWADisplayMode.BROWSER &&
        BrowserInfo.isCrhomeBasedBrowser())
    ) {

      // Make sure the after light version purchase the message is shown only once
      this.persistentState.set(
        Feature.BUY_ALL_CATEGORIES,
        false
      );

      const config: IInstallPromptConfig =
        this.appConfig.installPromptConfig;

      // Check how many time the message was shown
      const messageCount: number =
        this.persistentState.get(
          Feature.INSTALL_MESSAGE_COUNT,
          0
        );

      if (messageCount < config.maxDisplayCount) {


        // Check the period since it was shown last time
        const lastShown: number =
          this.persistentState.get(
            Feature.INSTALL_MESSAGE_TIME,
            0
          );
        const now: number = new Date().getTime();

        if (now - lastShown > config.displayPeriod * 60 * 60 * 1000) {

          // Update message count
          this.persistentState.set(
            Feature.INSTALL_MESSAGE_COUNT,
            messageCount + 1
          );

          // Update the last shown time
          this.persistentState.set(
            Feature.INSTALL_MESSAGE_TIME,
            now
          );

          // Show the message
          this.help.showPrompt();

          /*const messageId: string = BrowserInfo.isCrhomeBasedBrowser() ?
            'start_page.installation_prompt' :
            'start_page.installation_prompt_no_button';
    
          //this.alert.show('Display mode: '+InstallPrompt.getPWADisplayMode()+'<br><i> Is Chrome:</i> '+InstallPrompt.isCrhomeBasedBrowser());
          this.translate.get(messageId).
            subscribe(
              translation => {
    
                const url1: string = this.appConfig.tutorialPart1Url;
                const url2: string = this.appConfig.tutorialPart2Url;
    
                const message: string =
                  translation.replace('__URL1__', url1).
                    replace('__URL2__', url2);
    
                const copyright: string =
                  this.translate.instant('start_page.copyright');
    
                this.alert.show(message, copyright);
              }
            );*/
        }
      }
    }
  }
}
