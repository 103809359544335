import { Component, OnInit, Renderer2, ViewChild, ViewContainerRef, inject, DestroyRef, ChangeDetectionStrategy, output } from '@angular/core';
import { TestConfig } from 'src/app/models/config/config-types';
import { TestButtonComponent } from '../test-button/test-button.component'
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service'
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from 'src/app/global/services/subscription-api/subscription.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * The component is used to display the list of the test categories buttons
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-buttons',
  standalone: true,
  templateUrl: './test-buttons.component.html',
  styleUrls: ['./test-buttons.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestButtonsComponent implements OnInit {

  buttonClick = output<TestConfig>();

  @ViewChild('button_container', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  private config = inject(AppConfigService)
  private destroyRef = inject(DestroyRef);
  private renderer = inject(Renderer2);
  private subscriptionService = inject(SubscriptionService);
  private translate = inject(TranslateService);

  ngOnInit(): void {

    this.translate.onLangChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => this.loadButtons()
      );

    this.loadButtons();
  }


  onButtonClick(testConfig: TestConfig): void {
    this.buttonClick.emit(testConfig);
  }

  private loadButtons(): void {
    this.config.getTestConfigList(
      this.translate.currentLang
    ).subscribe(
      testButtons => {
        setTimeout(
          () => {
            this.createButtons(testButtons);
          }
        );
      }
    );
  }

  private createButtons(testButtons: TestConfig[]) {

    this.viewContainer.clear();
    testButtons.forEach(
      value => {
        this.createButton(value);
      }
    )
  }

  private createButton(testConfig: TestConfig) {

    const componentRef = this.viewContainer.createComponent(TestButtonComponent);

    componentRef.instance.config.set(testConfig.button);
    componentRef.instance.enabled.set(
      !testConfig.protected ||
      !this.subscriptionService.loginRequired
    );

    this.renderer.listen(
      componentRef.location.nativeElement,
      'click',
      () => { this.onButtonClick(testConfig) }
    );
  }
}
