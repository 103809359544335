import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { ButtonIcon, IButtonIcon } from '../toolbar-toggle-button/toolbar-button-icon';
import { RouterUrlList } from 'src/app/models/url-list';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'toolbar-help-button',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './toolbar-help-button.component.html',
  styleUrls: ['./toolbar-help-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarHelpButtonComponent implements OnInit {

  public readonly icon: IButtonIcon = ButtonIcon.help;
  public readonly routerLink: string = `/${RouterUrlList.HELP}`; 

  constructor() { }

  ngOnInit(): void {
  }

}
